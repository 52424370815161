<template>
  <div class="subPage container mt-3" v-if="this.$store.state.pageContent != null">
    <h1>{{ this.$store.state.pageContent.title.rendered }}</h1>
    <div v-html="this.$store.state.pageContent.content.rendered"></div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "SubPage",

  data() {
    return {
    };
  },
  created() {
    this.render();
  },
  methods: {
    render() {
      axios({
        method: "get",
        url:
          "https://bilhusetroskilde.dk/content//wp-json/wp/v2/pages?slug=" +
          this.$route.params.pageName,
      }).then((response) => {
        this.$store.dispatch("fetchPageContent", response.data[0]);
      });
    },
  },
  watch: {
    "$store.state.pageId": function () {
      this.render();
    },
  },
};
</script>
